import logo from './logo.svg';
import './App.css';
import Navboot from "./NavBoot";
import {Routes, Route, Link, useLocation} from "react-router-dom";
import Backg from "./components/backg";
import React from "react";
import Contact from "./routes/contact";
import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import {useState, Suspense, useEffect} from "react";

import leaf from './black-leaf.jpg';
import color from './colourful.jpg';
import Nav from "react-bootstrap/Nav";
import Skills1 from "./components/Skills1";
import Employment from "./components/Employment";
import Title from "./components/Title";

import checklist from "./icons/checklist.gif";
import hacker from "./icons/hacker.gif";
import message from "./icons/message.gif";
import mortarboard from "./icons/mortarboard.gif";
import diploma from "./icons/diploma.gif";
import certificate from "./icons/certificate.gif";
import ParticleImage from "./components/ParticleImage";
import AIPhotoEffect from "./components/AIPhotoEffect";
import {TabPane} from "react-bootstrap";
import Publications from "./components/Publications";
import Publications_List from "./components/Publications_List";
import CitationsChart from "./components/CitationsChart";
import LoadingScreen from './components/LoadingScreen';
import ProjectBlock from "./components/ProjectBlock";
import imageSrc from "./components/image"; // Import the image from image.js
import quantumimg from "./components/quantumimg"; // Import the quantum image
import blockchainimg from "./components/blockchain"; // Import the blockchain image
import AIimg from "./components/AIimg"; // Import the AI image
import powerimg from './components/powerimg';
import huaweiIMG from './components/huaweiIMG';
import Chatbot from "./components/Chatbot"; // Import the Chatbot component
import ParallaxAbout from "./components/ParallaxAbout";
const Name = 'Parham Mohammadi';
const Message = 'Welcome to my website';
const Message2 = 'Blockchain Developer';
const Message3 = 'DevOps Engineer';
const Message4 = 'Machine Learning Engineer';

function App() {
    const [currentTab, setCurrentTab] = React.useState("About Me");
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading time
        setTimeout(() => {
            setIsLoading(false);
        }, 3000); // 3 seconds loading time, adjust as needed
    }, []);

    React.useEffect(() => {
        const path = location.pathname.slice(1);
        if (path) {
            setCurrentTab(path.charAt(0).toUpperCase() + path.slice(1));
        } else {
            setCurrentTab("About Me");
        }
    }, [location]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="App">
            <div className="full-screen-bg" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
                <AIPhotoEffect />
            </div>
            <header className="App-header" style={{ backgroundColor: 'transparent', position: 'relative', zIndex: 1 }}>
                <Parallax pages={4} style={{ backgroundColor: 'transparent' }}>
                    <ParallaxLayer offset={0} speed={0} factor={3} style={{
                        backgroundColor: "rgba(255,255,255,0)",
                        backgroundSize: 'cover',
                        zIndex: 100
                    }}>
                        <Navboot setCurrentTab={setCurrentTab} />
                        {currentTab === "About Me" && (
                            <div>
                                <Title SectionTitle={"About Me"}/>
                                <div className="about-me-content">
                                    <ParallaxAbout 
                                        projects={[
                                            {
                                                title: "About Me",
                                                description: "I am an MLOps specialist and researcher with a PhD in electrical engineering and computer science from York University, focusing on deploying and optimizing machine learning systems across different scales - from TinyML to large language models. My expertise lies in implementing end-to-end MLOps practices for complex ML applications, particularly in time series analysis and efficient model deployment.\n" +
                                                    "In my current role at Huawei, I architect robust CI/CD pipelines for ML models, automate deployment workflows, and design scalable infrastructure for data processing and model training. I have extensive experience optimizing models for resource-constrained environments through TinyML, as well as managing and fine-tuning large language models for production environments.\n" +
                                                    "I specialize in developing and deploying ML systems across different computational scales, from edge devices to cloud infrastructure. Using technologies like Docker and Kubernetes, I implement containerized solutions that ensure consistent model performance across diverse environments. My expertise spans modern ML frameworks and tools including Python, Julia, TensorFlow, and major cloud platforms.\n" +
                                                    "A significant part of my work involves designing monitoring and observability systems for ML models in production, with particular attention to time series forecasting applications and LLM deployment optimization. I build robust logging systems that track model performance, drift, and resource utilization across different deployment scenarios.\n" +
                                                    "Previously, I served as a Teaching Assistant at York University, where I taught advanced algorithm design and analysis to undergraduate students, developing my ability to communicate complex technical concepts effectively.",
                                                technologies: ["AI", "Quantum Computing", "Full-Stack Development", "Cloud Architecture"],
                                                isAboutMe: true
                                            },
                                            {
                                                title: "AI & Machine Learning",
                                                description: "Experienced in developing sophisticated machine learning models and AI systems using modern frameworks. Specialized in natural language processing, computer vision, and predictive analytics with various industry applications.",
                                                technologies: ["TensorFlow", "PyTorch", "NLP", "Computer Vision"]
                                            },
                                            {
                                                title: "Cloud & DevOps",
                                                description: "Proficient in cloud infrastructure management and DevOps practices, with extensive experience in AWS and containerization. Skilled in implementing CI/CD pipelines and maintaining high-availability systems.",
                                                technologies: ["AWS", "Docker", "Kubernetes", "Terraform"]
                                            },
                                            {
                                                title: "Quantum Computing Research",
                                                description: "Actively researching quantum algorithms and their practical applications in optimization and simulation problems. Experienced with quantum frameworks and quantum machine learning techniques.",
                                                technologies: ["Qiskit", "Q#", "Quantum Algorithms", "IBM Quantum"]
                                            },
                                            {
                                                title: "Full-Stack Development",
                                                description: "Extensive experience in building modern web applications using React, Node.js, and various backend technologies. Skilled in developing RESTful APIs and implementing secure authentication systems.",
                                                technologies: ["React", "Node.js", "Python", "MongoDB"]
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        )}
                        {currentTab === "Experience" && (
                            <div>
                                <Title SectionTitle={"Experience"}/>
                                <Employment company={"Huawei"} date={"Jul 2023 - Present"}
                                            title={"Associate Researcher"}
                                            description={"Algorithm Development: Designing and refining machine learning models to accurately predict energy patterns and efficiency in microgrid systems." +
                                                " This involves handling vast datasets and ensuring algorithms are both robust and adaptable to changing environmental factors.\n\nData Analysis & Interpretation:" +
                                                " Conducting thorough statistical analyses to interpret complex time series data. This role involves transforming raw data into insightful" +
                                                " trends and actionable information, aiding in strategic decision-making processes.\n\nInnovation in Renewable Energy:" +
                                                " Contributing to Huawei's commitment to renewable energy through the implementation of cutting-edge technologies." +
                                                " My work supports the optimization of photovoltaic systems, enhancing their efficiency and reliability.\n\nCollaboration & Knowledge Sharing:" +
                                                " Working closely with cross-functional teams to integrate machine learning insights into broader energy projects."}/>
                                <Employment company={"Lagrange Labs"} date={"Aug 2022 - July 2023"}
                                            title={"Blockchain Developer"}
                                            description={"With a versatile skill set that includes proficiency in Terraform, Docker technologies, and front-end and back-end development using React.js and Node.js, I have demonstrated capabilities in managing complex IT infrastructures. My tenure at Lagrange Labs involved optimizing cloud computing strategies, managing infrastructure as code, and containerizing applications, which allowed for streamlined operations. My hands-on experience and in-depth understanding of these technologies enable me to build scalable and efficient full-stack solutions."}/>
                                <Employment company={"York University"} date={"Sep 2019 - Present"}
                                            title={"Teaching Assistant"}
                                            description={"Adapt teaching methods and instructional materials to meet students' varying needs, abilities, and interests.\n" +
                                                "Assign and grade classwork.\n" +
                                                "Keep regularly scheduled office hours to advise and assist students.\n" +
                                                "Prepare and administer written, oral, and performance tests, and issue grades in accordance with performance.\n" +
                                                "Observe and evaluate students' work to determine progress and make\n" +
                                                "suggestions for improvement.\n" +
                                                "I have been the teaching assistant for the following courses over the last two years:" +
                                                "1. Teaching Assistant-Computing for Psychology ( EECS 1570-Winter semester 2022 )\n" +
                                                "2. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Winter semester 2022 )\n" +
                                                "3. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Fall semester 2021 )\n" +
                                                "4. Teaching Assistant-Computational Thinking ( EECS 1011-Fall semester 2021)\n" +
                                                "5. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Winter semester 2021 )\n" +
                                                "6. Teaching Assistant-Computer Use: Fundamentals ( EECS 1520-Winter semester 2021 )\n" +
                                                "7. Teaching Assistant-Introduction to Embedded Systems ( EECS 2032-Fall semester 2020 )\n" +
                                                "8- Teaching Assistant-Introduction to Embedded Systems ( EECS\n" +
                                                "2032-Fall semester 2022 )" + "9- Teaching Assistant -  LE/EECS 3101 Design and Analysis of Algorithms (Winter semester 2023) "}/>
                                <Employment company={"York University"} date={"Sep 2019 - Present"}
                                            title={"Research Assistant"}
                                            description={"Performing research and making cutting-edge sets of computational and numerical tools for analyzing the energy industry, which is growing quickly.\n" +
                                                "My PhD thesis title is \"Classical and Quantum Computing Methods for Simulation of Geomagnetic Disturbance Effects on Modern Power Systems\""}/>
                                <Employment company={"MeshGrid HR"} date={"Feb 2022 - Apr 2022"} title={"NLP Engineer"}
                                            description={"Creating NLP solutions for web automation and resume scanning based on well known NLP libraries such as SPACY and nltk"}/>
                                <Employment company={"ChargeLab"} date={"Feb 2021 - Dec 2021"}
                                            title={"Research and Development Engineer"}
                                            description={"I was in charge of :\n" +
                                                "Creating new machine learning algorithms for EV charging systems.\n" +
                                                "weekly meetings with the chief technology officer and the preparation of industrial proposals.\n" +
                                                "Creating a timetable for the proposed projects using project management skills such as a Gantt chart."}/>
                                <Employment company={"IBZ-Mitacs Accelerate"} date={"May 2020 - Dec 2020"}
                                            title={"Entrepreneurship Intern"}
                                            description={"Lab2Market (L2M) is the first national-level program in Canada to fund and equip graduate students and their faculty supervisors with the entrepreneurial skills needed to assess the commercial viability of their university-based research innovations. I was part of this program with collaboration with VentureWell and Mitacs .\n" +
                                                "During my internship I was being prepared for:\n" +
                                                "Creating Business Models and Customer Development\n" +
                                                "Creating Value Proposition\n" +
                                                "Creating Customer Relationships\n" +
                                                "Creating Revenue Models\n" +
                                                "Getting Familiar with the Innovation Within Platform ( https://innovationwithin.com/ )\n" +
                                                "Creating Complete Business Model Canvas ( BMC ) for creating a new startup"}/>
                            </div>
                        )}
                        {currentTab === "Education" && (
                            <div>
                                <Title SectionTitle={"Education"}/>
                                <Employment company={"York University"} date={"Sep 2019 - 2023"}
                                            title={"PhD in Computer Science and Electrical Engineering"}
                                            description={"Performing research and making cutting-edge sets of computational and numerical tools for analyzing the energy industry, which is growing quickly.\n" +
                                                "My PhD thesis title is \"Classical and Quantum Computing Methods for Simulation of Geomagnetic Disturbance Effects on Modern Power Systems\""}/>
                                <Employment company={"Amir Kabir University of Technology"} date={"Sep 2016 - 2019"}
                                            title={"Master of Science - MS"} description={""}/>
                                <Employment company={"Iran University of Science and Technology"}
                                            date={"Sep 2011 - 2015"} title={"Bachelor of Science -BS"}
                                            description={""}/>
                            </div>
                        )}
                        {currentTab === "Skills" && (
                            <div>
                                <Title SectionTitle={"Skills"}/>
                                <Skills1 skills={"Amazon Web Services"} percentage={100}/>
                                <Skills1 skills={"Devops Engineering ( Terraform , Docker , Version Control , AWS)"}
                                         percentage={100}/>
                                <Skills1 skills={"Python Programming"} percentage={100}/>
                                <Skills1 skills={"C++ Programming"} percentage={80}/>
                                <Skills1 skills={"Go Programming"} percentage={80}/>
                                <Skills1 skills={"Rust Programming"} percentage={50}/>
                                <Skills1 skills={"Blockchain Fundamentals"} percentage={100}/>
                                <Skills1 skills={"Blockchain Cloud Management - Node Infrastructure"} percentage={100}/>
                                <Skills1 skills={"Quantum Computing Fundamentals"} percentage={100}/>
                                <Skills1 skills={"Database Architecture ( Leveldb - SQLite - etc )"} percentage={50}/>
                                <Skills1 skills={"CSS-HTML-Javascript"} percentage={100}/>
                                <Skills1 skills={"Full-Stack Developer ( Node js - django - React )"} percentage={100}/>
                                <Skills1 skills={"Machine Learning ( Python - TensorFlow - Keras )"} percentage={100}/>
                                <Skills1 skills={"Data Science ( Python - Pandas - Numpy - Matplotlib )"}
                                         percentage={100}/>
                                <Skills1 skills={"C# Programming - WPF and MVVM"} percentage={50}/>
                                <Skills1 skills={"Rest API"} percentage={80}/>
                                <Skills1 skills={"Quantum Computing ( Qiskit - IBM Quantum Experience )"}
                                         percentage={100}/>
                                <Skills1 skills={"Blockchain concepts and foundation"} percentage={100}/>
                                <Skills1 skills={"NLP ( SPACY - nltk )"} percentage={100}/>
                                <Skills1 skills={"Computer Vision ( OpenCV )"} percentage={100}/>
                            </div>
                        )}
                        {currentTab === "Certificates" && (
                            <div>
                                <Title SectionTitle={"Certificates"}/>
                                <Employment company={"edx"} date={""}
                                            title={"Introduction to Quantum Computing for Everyone"}
                                            description={""}/>
                                <Employment company={"edx"} date={""}
                                            title={" Architecture, Algorithms, and Protocols of a Quantum Computer and Quantum Internet"}
                                            description={""}/>
                                <Employment company={"edx"} date={""}
                                            title={"Verified Certificate for Fundamentals of Quantum Information"}
                                            description={""}/>
                                <Employment company={"edx"} date={""} title={"Introduction to Quantum Computing"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""} title={"AWS Cloud Essentials"}
                                            description={""}/>
                                <Employment company={"edx"} date={""} title={"Bitcoin and Cryptocurrencies"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""} title={"Cryptography I"} description={""}/>
                                <Employment company={"Udemy"} date={""} title={"Complete C# Masterclass"}
                                            description={""}/>
                                <Employment company={"Udemy"} date={""}
                                            title={"Complete linear algebra: theory and implementation in code"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""}
                                            title={"Computers, Waves, Simulations: A Practical Introduction to Numerical Methods using Python"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""}
                                            title={"Introduction to virtual machines in Microsoft Azure"}
                                            description={""}/>
                                <Employment company={"IEEE"} date={""}
                                            title={"Project-based Python Workshop Series, Winter 2021"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""} title={"How Google does Machine Learning"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""} title={"Python Classes and Inheritance"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""}
                                            title={"Python Functions, Files, and Dictionaries"} description={""}/>
                                <Employment company={"Coursera"} date={""} title={"Neural Networks and Deep Learning"}
                                            description={""}/>
                                <Employment company={"Coursera"} date={""} title={"Machine Learning"} description={""}/>
                                <Employment company={"Coursera"} date={""} title={"Python Basics"} description={""}/>
                            </div>
                        )}
                        {currentTab === "Honors" && (
                            <div>
                                <Title SectionTitle={"Honors"}/>
                                <Employment company={"NSERC"} date={"2024"} title={"\n" +
                                    "Lab2Market NSERC"}
                                            description={"In a pilot opportunity, NSERC is partnering with the This link will take you to another Web site Lab2Market program to offer teams" +
                                                " (researcher and student, supported by their ILO) the chance to assess the commercial potential for their technology. The program is based on successful initiatives" +
                                                " elsewhere in the world and consists of a 16-week program to help researchers validate their ideas with the purpose of finding business/commercial value. " +
                                                "Funding is available for up to 12 months, with a maximum contribution from NSERC of $20,000, of which $5,000 will be used to pay the Lab2Market consultants, $10,000 will be paid to the Entrepreneurial Lead (student) as a stipend, and $5,000 will be held by the principal investigator for program-related expenses" +
                                                " (e.g., travel, mentoring, consultation, etc.)." +
                                                " NSERC will assume 100% of the costs of the program, and the grant is non-renewable."}/>
                                <Employment company={"York University"} date={"2024"}
                                            title={"\n" + "Commercialization Fellowships"}
                                            description={"Commercialization Fellowships, funded by the Office of the Vice-President Research & Innovation (VPRI), are intended to support the research commercialization process by providing strategic, short-term funding to assist in the development of " +
                                                "commercially viable projects. The aim is to prepare projects for commercial engagement with industry and/or community partners," +
                                                " with a view to licensing and/or co-development of the project intellectual property.\n" +
                                                "\n" +
                                                "The fellowships are offered on a short-term, part-time basis and are intended to run alongside existing research work without detracting from it. It is envisaged that fellowships should not account for more than 8-10 hours per week for up to 4 months."}/>
                                <Employment company={"York University"} date={"2021"} title={"Carswell Scholarship"}
                                            description={"The Carswell Scholarships will be awarded to incoming doctoral students in the Lassonde School of\n" +
                                                "                Engineering, who demonstrate outstanding academic achievement. The value of the scholarship is 10000\n" +
                                                "                CAD."}/>
                                <Employment company={"Mitacs"} date={"2020"} title={"Lab2Market Research Award"}
                                            description={"Lab2Market Program, which will provide training and support to teams looking to commercialize"}/>
                                <Employment company={"LG Global Challenger Award"} date={"2019"}
                                            title={"LG Global Challenger A research emerging from Canadian universities. The Value of the award is 15000 CADward"}
                                            description={""}/>
                                <Employment company={"York University"} date={"2019"}
                                            title={"York University Graduate Fellowship Doctoral Scholarship"}
                                            description={""}/>
                                <Employment company={"APEC 2021"} date={"2021"} title={"Best Presentation Award"}
                                            description={""}/>
                            </div>
                        )}
                        {currentTab === "Contact" && (
                            <div>
                                <Title SectionTitle={"Contact"}/>
                                <Employment company={"Email"} link={"parhammoe@ieee.org"}></Employment>
                                <Employment company={"Phone"} date={"+1-4374237704"}></Employment>
                                <Employment company={"LinkedIn"}
                                            link={"https://www.linkedin.com/in/parhammh/"}></Employment>
                                <Employment company={"Github"} link={"https://github.com/parhammhe"}></Employment>
                            </div>
                        )}
                        {currentTab === "Publications" && (
                            <div>
                                <Title SectionTitle={"Publications"}/>
                                <CitationsChart/>
                                <Publications_List/>
                            </div>
                        )}
                    </ParallaxLayer>
                </Parallax>
            </header>
            {/* Add the Chatbot component outside of any specific tab */}
            <Chatbot />
        </div>
    )
}

export default App;